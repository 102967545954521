import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import fair from "../../Assets/Projects/fair.png";
import store from "../../Assets/Projects/store.png";
import vpn from "../../Assets/Projects/vpn.png";
import inn from "../../Assets/Projects/inn.png";
import motaz from "../../Assets/Projects/motaz.png";
import ralf from "../../Assets/Projects/ralf.png";
import gocode from "../../Assets/Projects/go-n-code.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fair}
              isBlog={false}
              title="FairLance"
              description="FairLance is a groundbreaking platform transforming the freelancing industry. By using decentralized technology, we offer freelancers globally fair opportunities and freedom from excessive fees. Our innovative payment method overcomes industry limitations, revolutionizing how freelancers and service providers connect and operate."
              ghLink="https://github.com/MohamadSafi/Sui-Overflow-Hackathon"
              demoLink="sui-overflow-hackathon.vercel.app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gocode}
              isBlog={false}
              title="Go'N Code"
              description="Go N Code is an IT solutions provider in Dubai, offering web development, UI/UX design, mobile app development, and blockchain solutions. Their website showcases services, a project portfolio, and a blog. Built using Next.js, the site features a modular and scalable architecture emphasizing performance and maintainability. They focus on creative, customized solutions to enhance business growth and efficiency."
              ghLink="https://github.com/HaidarJbeily7/go-n-code"
              demoLink="https://go-n-code.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={store}
              isBlog={false}
              title="e-Commerce Store with
              Blockchain Payment
              Integration"
              description="It's a secure platform for buying courses using blockchain for transparent payments. It uses smart contracts for reliable course access, runs on a local Ganache blockchain, and integrates Web3 for MetaMask wallet interactions. The UI is crafted with Tailwind CSS for a sleek, responsive design."
              ghLink="https://github.com/MohamadSafi/ecommerce-store"
              demoLink="https://e-commerce-courses-store.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vpn}
              isBlog={false}
              title="Free Me VPN"
              description="Free Me VPN delivers a unique VPN service by offering free .ovpn configuration files to its website visitors. Designed for those prioritizing online privacy and security, this project covers VPN server setup, website creation, SEO, and design, providing a comprehensive, secure, and free VPN solution."
              ghLink="https://github.com/MohamadSafi/FreeVPN"
              demoLink="https://freemevpn.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={inn}
              isBlog={false}
              title="Advanced External Moodle Quiz Generator"
              description="
              To address the growing student population at Innopolis University, we're automating grading with Moodle quizzes to save time and ensure fairness. The challenge of reusing questions has led to the development of an external quiz generator tool. This tool crafts flexible, course-specific questions for Moodle, reducing manual work and maintaining quiz effectiveness."
              ghLink="https://github.com/MohamadSafi/quizzesadvanced-frontend"
              demoLink="qumogen.worldm.ru"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={motaz}
              isBlog={false}
              title="Motaz Pattern design"
              description="A groundbreaking app for garment designers and sewing enthusiasts. Integral to our exclusive online school course, this app enhances your design and sewing journey with its intuitive interface and precise functionalities. Suitable for all skill levels, Motaz Pattern Design delivers unparalleled benefits to its users."
              // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              demoLink="https://play.google.com/store/apps/details?id=com.billysafi.motazpatterndesign&hl=en&gl=US"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ralf}
              isBlog={false}
              title="Ralf Reifen"
              description="Ralf Reifen is a streamlined eCommerce app for buying and selling car parts in Germany. Users can quickly set up an account to either post their own products or search for and contact sellers directly. It simplifies transactions, making it easy to find or sell car parts efficiently."
              // ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
