import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { StarsCanvas } from "../canvas";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "left" }} className="text-base md:text-xl">
            Hi Everyone, I am <span className="purple">Mohammad Safi </span>
            I'm <span className="purple"> 21</span> years old
            <br />
            I Studied computer sience in Innopolis university cyber security
            track.
            <br />
            I have been working as a Software Engineer for couple years now.
            I've built a lot of websites, mobile applications and delivered a
            lot of products.
            <br />
            <br />
            Throughout my career, I have engaged extensively in freelancing,
            which has been instrumental in acquiring a diverse skill set ranging
            from front-end development in web and mobile applications to
            back-end systems.
            <br />
            My expertise primarily lies in leveraging React and React Native
            with TypeScript for front-end solutions, complemented by back-end
            development utilizing Express.js and Nest.js.
            <br />
            My design capabilities are honed through the use of Figma.
            <br />
            Additionally, my work on the Free Me VPN project has enriched my
            experience with server management, networking, and SEO, broadening
            my technical proficiency.
            <br />
            I perceive significant potential in blockchain technology, which I
            believe is gradually revolutionizing the industry.
            <br />
            <br />
            Beyond coding, I am passionate about networking, connecting with
            individuals, and diligently pursuing the realization of my dreams.{" "}
          </p>
          {/* <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul> */}

          {/* <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p> */}
          {/* <footer className="blockquote-footer">Soumyajit</footer> */}
        </blockquote>
      </Card.Body>
      {/* <StarsCanvas /> */}
    </Card>
  );
}

export default AboutCard;
